import * as action from '../actions/action';
import { getLanguage, setLanguage } from '../../utils/localeTools';
import { setInStorage } from '../../utils/storage';

const initialState = {
  isLoggedIn: false,
  defaultRoute: null,
  user: {},
  token: null,
  errAlert: '',
};

const reducer = (state = initialState, payload) => {
  let { defaultCalendar } = payload.user || 'gregorian';
  let { defaultLanguage } = payload.user || 'en-US';
  let { defaultTimezone } = payload.user || 'utc';

  switch (payload.type) {
    case action.LOGIN_USER:
      // localization
      if (defaultCalendar === undefined) {
        defaultCalendar = 'gregorian';
      }
      if (defaultLanguage !== getLanguage()) {
        setLanguage(defaultLanguage);
      }
      
      setInStorage('timezone', defaultTimezone);
      setInStorage('calendar', defaultCalendar);
      return {
        ...state,
        isLoggedIn: payload.isLoggedIn,
        defaultRoute: payload.defaultRoute,
        user: {
          ...payload.user,
          defaultCalendar,
        },
        token: payload.token,
      };

    case action.UPDATE_USER:
      if (defaultCalendar === undefined) {
        defaultCalendar = 'gregorian';
      }
      setInStorage('calendar', defaultCalendar);
      return {
        ...state,
        user: {
          ...payload.user,
          defaultCalendar,
        },
      };

    case action.STORE_TOKEN:
      return {
        ...state,
        token: payload.token,
      };

    case action.LOGOUT_USER:
      return {
        isLoggedIn: false,
        token: null,
        user: [],
        errAlert: '',
      };

    default:
      return state;
  }
};

export default reducer;
