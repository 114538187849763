import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import query from '../../utils/query';
import Content from '../../components/content';
import { getFromStorage } from '../../utils/storage';
import * as action from '../../store/actions/action';
import Loading from '../../components/theme/Loading';
import Sidebar from '../../components/theme/Sidebar';
import PageLoader from '../../components/common/PageLoader';
import RouteModule from '../../components/common/RouteModule';
import { getDirection, getLanguage, setLanguage } from '../../utils/localeTools';
import { BrowserRouter as Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import { addBodyClass, setDirectionClass, setLanguageClass } from '../../utils/utils';

import 'react-toastify/dist/ReactToastify.min.css';
import '../../components/3rdParties/Vendor';
import '../../styles/style.scss';
import { Login, ChangePassword, Organizations, Branches, OrganizationInfo, OrganizationUsers, BranchInfo } from './activeRoute';
import { BranchUsers, Users, Account, Clients, ClientOrganizations, AnnouncementsAdd, BranchesWithChanges } from './activeRoute';
import { ClientInfo } from './activeRoute';

const publicPages = [
  '',
  'login',
  'api-docs',
];

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      hasValidSession: getFromStorage('login'),
    };
    this.failedAuth = this.failedAuth.bind(this);
  }

  componentDidMount() {
    addBodyClass('theme-light');

    if (this.state.hasValidSession) {
      this.setState({ isLoading: true });
      query('GET', '/v1/me', null, false)
        .then(async (json) => {
          const { onLogin } = this.props;
          if (json.success) {
            await onLogin({ ...json, isLoggedIn: true });
            this.setState({
              isLoading: false,
            });
          } else {
            this.failedAuth();
          }
        });
    } else {
      this.failedAuth();
    }
  }

  failedAuth() {
    const { account, onLogout } = this.props;
    onLogout();
    account.user = {};
    account.isLoggedIn = false;
    const host = `${window.location.protocol}//${window.location.host}`;
    const href = window.location.href.replace(host, '');
    const params = href.split('/');
    if (!publicPages.includes(params[1])) {
      window.location.href = '/login';
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    const { account, theme, onCloseAside } = this.props;
    const language = account.user.defaultLanguage || getLanguage();
    if (
      account.user.defaultLanguage !== 'undefined'
      && account.user.defaultLanguage !== getLanguage()
    ) {
      setLanguage(account.user.defaultLanguage);
    }
    setDirectionClass(getDirection(language));
    setLanguageClass(language);

    const mainClass = theme.openAside ? 'main collapsed-sidebar' : 'main';

    if (this.state.isLoading === true && this.state.hasValidSession) {
      return (
        <div className={mainClass}>
          <Loading />
        </div>
      );
    }

    if (account.isLoggedIn === false) {
      return (
        <Router>
          <div>
            <Suspense fallback={<PageLoader />}>
              <Switch>
                {/* Removes trailing slashes */}
                <Route
                  path="/:url*(/+)"
                  exact
                  strict
                  render={({ location }) => <Redirect to={location.pathname.replace(/\/+$/, '')} />}
                />
                {/* Removes duplicate slashes in the middle of the URL */}
                <Route
                  path="/:url(.*//+.*)"
                  exact
                  strict
                  render={({ match }) => (
                    <Redirect to={`/${match.params.url.replace(/\/\/+/, '/')}`} />
                  )}
                />
                <Route exact path="/login" component={Login} />
                <Route path="*">
                  <Redirect to="/login" />
                </Route>
              </Switch>
            </Suspense>
          </div>
        </Router>
      );
    }
    const modules = account.user && account.user.modules
      ? account.user.modules.split(',')
      : [];

    const defaultRoute = account.defaultRoute || `/${modules[0]}`;

    return (
      <Router>
        <div className={mainClass}>

          <Sidebar />
          <div className="sidebar-cover" onClick={onCloseAside} />
          <Suspense fallback={<PageLoader />}>
            <Switch>

              <Route
                path="/:url*(/+)"
                exact
                strict
                render={({ location }) =>
                  <Content>
                    <Redirect to={location.pathname.replace(/\/+$/, '')} />
                  </Content>
                }
              />
              {/* Removes duplicate slashes in the middle of the URL */}
              <Route
                path="/:url(.*//+.*)"
                exact
                strict
                render={({ match }) => (
                  <Content>
                    <Redirect to={`/${match.params.url.replace(/\/\/+/, '/')}`} />
                  </Content>
                )}
              />
              <Route
                module="account"
                path="/account"
                component={(props) =>
                  <Content>
                    <Account {...props} />
                  </Content>
                }
              />
              <Route
                module="organizations"
                path="/organizations/:uuid/users"
                component={(props) =>
                  <Content>
                    <OrganizationUsers {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/organizations/:uuid"
                component={(props) =>
                  <Content>
                    <OrganizationInfo {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/organizations"
                component={(props) =>
                  <Content>
                    <Organizations {...props} />
                  </Content>
                }
              />
              <Route
                module="branches"
                path="/branches/branches-with-changes"
                component={(props) =>
                  <Content>
                    <BranchesWithChanges {...props} />
                  </Content>
                }
              />


              <Route
                module="branches"
                path="/branches/:uuid/users"
                component={(props) =>
                  <Content>
                    <BranchUsers {...props} />
                  </Content>
                }
              />

              <Route
                module="branches"
                path="/branches/:uuid?/info"
                component={(props) =>
                  <Content>
                    <BranchInfo {...props} />
                  </Content>
                }
              />

              <Route
                module="branches"
                path="/branches/:uuid?"
                component={(props) =>
                  <Content>
                    <Branches {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/users"
                component={(props) =>
                  <Content>
                    <Users {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/clients/:uuid?/organizations"
                component={(props) =>
                  <Content>
                    <ClientOrganizations {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/clients/:uuid?/info"
                component={(props) =>
                  <Content>
                    <ClientInfo {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/clients"
                component={(props) =>
                  <Content>
                    <Clients {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/messages/:senderType?/:senderId?/:type?"
                component={(props) =>
                  <Content>
                    <AnnouncementsAdd {...props} />
                  </Content>
                }
              />

              <Route
                module="organizations"
                path="/messages"
                component={(props) =>
                  <Content>
                    <AnnouncementsAdd {...props} />
                  </Content>
                }
              />




              <Route
                path="/changePassword"
                component={
                  (props) => <Content><ChangePassword {...props} /></Content>
                }
              />

              <Route path="/*">
                <Redirect to="/organizations" />
              </Route>
            </Switch>
          </Suspense>

          {/*<Footer />*/}
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
  theme: state.themeReducer,
});
const mapDispatchToProps = (dispatch) => ({
  onLogin: (payload) => dispatch({ ...payload, type: action.LOGIN_USER }),
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
  onCloseAside: () => dispatch({ type: action.CLOSE_ASIDE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
