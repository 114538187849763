import {lazy} from "react";

export const Login = lazy(() => import('../auth/content/Login'));
export const Account = lazy(() => import('../account/content/Account'));
export const ChangePassword = lazy(() => import('../auth/content/ChangePassword'));

export const Organizations = lazy(() => import('../organizations/content/Organizations'));
export const OrganizationInfo = lazy(() => import('../organizations/content/OrganizationInfo'));
export const OrganizationUsers = lazy(() => import('../organizations/content/OrganizationUsers'));

export const Branches = lazy(() => import('../branches/content/Branches'));
export const BranchesWithChanges = lazy(() => import('../branches/content/BranchesWithChanges'));
export const BranchUsers = lazy(() => import('../branches/content/BranchUsers'));
export const BranchInfo = lazy(() => import('../branches/content/BranchInfo'));

export const Clients = lazy(() => import('../clients/content/Clients'));
export const ClientInfo = lazy(() => import('../clients/content/ClientInfo'));
export const ClientOrganizations = lazy(() => import('../clients/content/ClientOrganizations'));

export const AnnouncementsAdd = lazy(() => import('../announcements/content/AnnouncementsAdd'));


export const Users = lazy(() => import('../users/content/Users'));
