import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withNamespaces, Trans } from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import * as action from '../../store/actions/action';
import query from '../../utils/query';
import { getGravatar } from '../../utils/utils';

import 'react-toastify/dist/ReactToastify.css';
import { removeStorage } from '../../utils/storage';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoutStatus: false,
    }
    this.logout = this.logout.bind(this);
  }

  defaultPhoto = (e) => {
    e.target.src = '/images/avatar2.png';
  };

  logout(e) {
    e.preventDefault();
    query('POST', '/v1/auth/logout').then(() => {
      this.props.onLogout();
      this.setState({ logoutStatus: true });
      removeStorage('login');
    });
  }

  render() {
    const { account } = this.props;

    if (account && account.isLoggedIn === false) {
      return <br />;
    }

    return (
      <>
        <div className="main-header m-3">
          <div className="">
            <div className="profile dropdown">
              <div
                className="dropdown-button dropdown-toggle"
                id="dropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="description d-flex">
                  <p className="name">
                    {account.user.preferredName || account.user.firstName}
                  </p>
                  <i className="icon-angle-down" />
                </span>
              </div>
              <div className="dropdown-list" aria-labelledby="dropdownMenuLink">
                    <Link to="/account" className="dropdown-item">
                      <Trans i18nKey="header.ACCOUNT">User Account</Trans>
                    </Link>
                    <Link to="/changePassword" className="dropdown-item">
                      <Trans i18nKey="header.CHANGE_PASSWORD">Change Password</Trans>
                    </Link>
                <hr />
                <div className="dropdown-item cursor-pointer moreBtn" onClick={this.logout} role="button" onKeyPress={this.logout} tabIndex={0}>
                  <Trans i18nKey="header.LOGOUT">Logout</Trans>
                </div>
              </div>
            </div>
          </div>

        </div>
        {this.state.logoutStatus && <Redirect to='/'/>}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch({ type: action.LOGOUT_USER }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translations')(Header));
