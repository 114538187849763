const sideMenu = [
  {
    name: 'Organizations',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.ORGANIZATIONS',
    path: '/organizations',
    module: 'organizations',
  },
  {
    name: 'branches',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.BRANCH_WITH_CHANGES',
    path: '/branches/branches-with-changes',
    module: 'organizations',
  },
  {
    name: 'Users',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.USERS',
    path: '/users',
    module: 'users',
  },
  {
    name: 'Clients',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.CLIENTS',
    path: '/clients',
    module: 'organizations',
  },
  {
    name: 'Messages',
    icon: 'icon-Shape',
    translate: 'sidebar.nav.MESSAGES',
    path: '/messages',
    module: 'organizations',
  },
];

export default sideMenu;
